@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/layout.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';

/* stylelint-disable */
:export {
  single-column-max-width: $breakpoint-tablet;
}
/* stylelint-enable */

.withLogo {
  position: relative;
  margin-top: units(2);
}

.heading {
  padding: units(1.5);
  max-width: columns(18);
  margin: auto;
  margin-bottom: units(1);

  @include fromBreakpoint(tablet) {
    padding: 0;
  }

  @include breakpoint('phone') {
    padding: units(1.5) units(1.25);
  }
}

.logo {
  position: absolute;
  top: 0;
  height: columns(4);
  padding: units(0.5);
  background: color('White');
  margin-left: units(1);
  margin-bottom: columns(1);
  display: block;

  /* align left of logo with container edge */
  left: calc((100vw - #{$container-large-width}) / 2);

  @media screen and (max-width: #{$container-large-width}) {
    left: 0;
    height: columns(3);
    margin-bottom: columns(0.5);
  }

  @include breakpoint(tablet) {
    height: columns(2);
  }

  @include breakpoint(phablet) {
    height: columns(1.5);
    padding: units(0.375);
    margin-bottom: columns(0.25);
  }
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 7vw;

  @include breakpoint(tablet) {
    display: block;
    transform: none;
  }
}

.feature ~ .container {
  margin-top: units(-4);

  @include breakpoint('phone') {
    margin-top: units(-2);
  }
}

/* Styles included directly by child components */

.contentWrapper {
  // IE11 needs a flex-basis and therefor we need explicit grow
  // and shrink values
  flex: 1 1 100%;
  margin-left: units(1);
  margin-right: units(1);
}

.sideBar {
  flex-shrink: 0;
  min-width: columns(5);
  transform: translateY(units(-2));

  /* psuedo-fullscreen.
   *
   * this is when we have sidebar on the side but the content is still adjusting
   * along with the max-width of the page
   */
  margin-left: units(1);
  margin-right: units(1);

  /* real fullscreen
   *
   * content+sidebar is now floating in the centre of the page with
   * auto margins. give the sidebar extra space from content
   */
  @media screen and (min-width: #{$container-large-width}) {
    margin-left: units(3);
    transform: translateY(units(-4));
  }

  @include breakpoint(tablet) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 100%;
    transform: none;
  }

  > * + * {
    margin-top: units(1);
  }
}

.pageLandingWrapper {
  width: 100%;
  margin: 0 auto;

  @include fromBreakpoint(tablet) {
    max-width: 656px;
  }
}
